<template>
  <div class="p-3">
    <appCheckBankruptcyView
      :propsData1="getUserFromId"
      :propsData2="getBankruptcyRecord"
    ></appCheckBankruptcyView>
  </div>
</template>
<script>
import appCheckBankruptcyView from "../../components/checkBankruptcy/checkBankruptcyView";
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "CheckBankruptcyView",
  components: {
    appCheckBankruptcyView,
  },
  data() {
    return {
      loginUserObj: {},
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getBankruptcyRecord", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchBankruptcyRecord", "fetchUserFromId"]),
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchBankruptcyRecord(this.projectId);
  },
};
</script>

